/*
 * This is dummy file and does nothing.
 * 
 * You do not need to include it for English localization.
 * 
 * Translators!
 * If you want to make new localization use 'elrte.YOUR_LANG.js' file
 * in this directory as template.
 * Please submit your localization by creating new issue:
 * http://elrte.org/redmine/projects/elrte/issues/new
 */
